<template>
  <div class="container cgu">
    <h1>Conditions générales d&#39;utilisation de Cap Surrénales</h1>
    <p class="info">En vigueur au 01/01/2021</p>
    <p>
      Les présentes conditions générales d&#39;utilisation (dites « CGU ») ont
      pour objet l&#39;encadrement juridique des modalités de mise à disposition
      du site et des services par EDUSANTE et de définir les conditions d’accès
      et d’utilisation des services par « l&#39;Utilisateur ». <br />
      Les présentes CGU sont accessibles sur le site à la rubrique «CGU».
    </p>

    <h2>Article 1 : Les mentions</h2>

    <p>
      légales L&#39;édition de l’application mobile CAP surrénales est assurée
      par la Société SAS EDUSANTE au capital de 32 000 euros, immatriculée au
      RCS de Paris B 411 203 565 sous le numéro 411203565, dont le siège social
      est situé au 15 RUE JOSEPH BARA 75006 PARIS <br />
      Numéro de téléphone 01 40 13 78 29 <br />
      Adresse e-mail : edusante@edusante.fr. <br />
      Le Directeur de la publication est : BEGUIN Francois-Xavier
    </p>
    <p>
      L’application mobile CAP surrénales est issue du site
      https://www.edusante.fr/cap-surrenales et est encapsulée dans une webview
      avec l’utilitaire Cordova.
    </p>

    <h2>ARTICLE 2 : Accès à l’application mobile</h2>

    <p>
      L’application CAP Surrénales permet à l&#39;Utilisateur un accès gratuit
      aux services suivants :
    </p>
    <p>
      Permettre à des patients atteints d’insuffisance surrénale de compléter
      leur suivi thérapeutique sur les comportements à avoir dans le cadre de
      leur maladie.
    </p>
    <p>
      L’application est accessible gratuitement en tout lieu à tout Utilisateur
      ayant un accès à Internet et aux stores proposant l’application. Tous les
      frais supportés par l&#39;Utilisateur pour accéder au service (matériel
      informatique, logiciels, connexion Internet, etc.) sont à sa charge. 2
    </p>

    <h2>ARTICLE 3 : Collecte des données</h2>

    <p>
      L’application est exemptée de déclaration à la Commission Nationale
      Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune
      donnée concernant les Utilisateurs.
    </p>

    <h2>ARTICLE 4 : Propriété intellectuelle</h2>

    <p>
      Les marques, logos, signes ainsi que tous les contenus du site (textes,
      images, son…) font l&#39;objet d&#39;une protection par le Code de la
      propriété intellectuelle et plus particulièrement par le droit
      d&#39;auteur.
    </p>
    <p>
      La marque EDUSANTE est une marque déposée par EDUSANTE. Toute
      représentation et/ou reproduction et/ou exploitation partielle ou totale
      de cette marque, de quelque nature que ce soit, est totalement prohibée.
    </p>
    <p>
      L&#39;Utilisateur doit solliciter l&#39;autorisation préalable du site
      pour toute reproduction, publication, copie des différents contenus. Il
      s&#39;engage à une utilisation des contenus du site dans un cadre
      strictement privé, toute utilisation à des fins commerciales et
      publicitaires est strictement interdite. Toute représentation totale ou
      partielle de ce site par quelque procédé que ce soit, sans l’autorisation
      expresse de l’exploitant du site Internet constituerait une contrefaçon
      sanctionnée par l’article L 335-2 et suivants du Code de la propriété
      intellectuelle. Il est rappelé conformément à l’article L122-5 du Code de
      propriété intellectuelle que l’Utilisateur qui reproduit, copie ou publie
      le contenu protégé doit citer l’auteur et sa source.
    </p>
    <h2>ARTICLE 5 : Responsabilité</h2>
    <p>
      Les sources des informations diffusées sur l’application CAP Surrénales
      sont réputées fiables mais l’application ne garantit pas qu’elle soit
      exempt de défauts, d’erreurs ou d’omissions.
    </p>
    <p>
      Les informations communiquées sont présentées à titre indicatif et général
      sans valeur contractuelle. Malgré des mises à jour régulières,
      l’application CAP Surrénales ne peut être tenue responsable de la
      modification des dispositions administratives et juridiques survenant
      après la publication. De 3 même, l’application ne peut être tenue
      responsable de l’utilisation et de l’interprétation de l’information
      contenue dans ce site.
    </p>
    <p>
      L’application CAP Surrénales ne peut être tenue pour responsable
      d’éventuels virus qui pourraient infecter l’ordinateur ou tout matériel
      informatique de l’Internaute, suite à une utilisation, à l’accès, ou au
      téléchargement provenant de cette application. La responsabilité de
      l’application ne peut être engagée en cas de force majeure ou du fait
      imprévisible et insurmontable d&#39;un tiers.
    </p>

    <h2>ARTICLE 6 : Liens hypertextes</h2>

    <p>
      Des liens hypertextes peuvent être présents sur l’application.
      L’Utilisateur est informé qu’en cliquant sur ces liens, il sortira de
      l’application. Cette dernière n’a pas de contrôle sur les pages web sur
      lesquelles aboutissent ces liens et ne saurait, en aucun cas, être
      responsable de leur contenu.
    </p>
    <h2>ARTICLE 7 : Cookies</h2>

    <p>L’Utilisateur est informé que l’application n’utilise pas de cookies.</p>

    <h2>ARTICLE 8 : Droit applicable et juridiction compétente</h2>
    <p>
      La législation française s&#39;applique au présent contrat. En cas
      d&#39;absence de résolution amiable d&#39;un litige né entre les parties,
      les tribunaux français seront seuls compétents pour en connaître. Pour
      toute question relative à l’application des présentes CGU, vous pouvez
      joindre l’éditeur aux coordonnées inscrites à l’ARTICLE 1.
    </p>
  </div>
</template>

<script>
export default {
  name: "mobile"
};
</script>
<style lang="scss" scoped>
.cgu {
  h1 {
    font-size: 28px;
    font-weight: 600;
    width: 70%;
    margin: 0 auto;
    margin-top: 10%;
    text-align: center;
    line-height: 1.1em;
  }
  .info {
    font-style: italic;
    text-align: center;
    margin-bottom: 5%;
  }
  h2 {
    font-size: 21px;
    font-weight: 600;
    margin-top: 5%;
  }
  p {
    font-size: 18px;
    margin-top: 2%;
  }
}
</style>
